import { ScreenOrientation } from '../FeatureAppTypes';
import { getWindow } from './get-window';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const getOrientation = (): ScreenOrientation => {
  if (typeof window !== 'object' || !window.matchMedia) {
    const { innerHeight = 0, innerWidth = 0 } = getWindow();

    return innerWidth >= innerHeight ? ScreenOrientation.landscape : ScreenOrientation.portrait;
  }

  return window.matchMedia('(orientation: portrait)').matches
    ? ScreenOrientation.portrait
    : ScreenOrientation.landscape;
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const isLandscape = () => {
  return getOrientation() === ScreenOrientation.landscape;
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const isPortrait = () => {
  return getOrientation() === ScreenOrientation.portrait;
};
