import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { useContext } from 'react';
import { I18nMessageKey, I18nMessages } from '../FeatureAppTypes';
import { I18nContext } from './i18nContext';

const i18nScope = 'fa.product-highlights';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export function useI18n(key: I18nMessageKey): string {
  const { i18nMessages } = useContext(I18nContext);

  return i18nMessages[key] ?? `__${i18nScope}.${key}__`;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export function matchI18nWithValues(
  i18nKey: I18nMessageKey,
  params: { [key: string]: string }
): string {
  const textFromI18n = useI18n(i18nKey);

  if (params) {
    return Object.keys(params)
      .map((key: string): string => textFromI18n?.replace(`\${${key}}`, params[key]))
      .toString();
  }

  return textFromI18n;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export async function fetchI18nMessages(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1
): Promise<I18nMessages> {
  return new Promise((resolve, reject) => {
    try {
      i18nService.setScopes([i18nScope]);
      i18nService.registerCallback((messages) => {
        const res = Object.entries(messages[localeService.language]).reduce(
          (acc: Record<string, string>, [key, value]) => {
            // TODO: remove this as soon as i18nService is CMS agnostic (keys don't required FA prefix/scope)
            // messages are prefixed with the i18nScope (feature app name: fa.cct-teaser). This is required due to
            // how AEM handles translations. In order to avoid verbosity in the source code the scope is removed from
            // the keys to make them match the required I18nMessageKey.
            // This way translations can be used like `useI18n('headline')` instead of `useI18n('fa.cct-teaser.headline')`
            if (key.includes(i18nScope)) {
              // eslint-disable-next-line no-param-reassign
              key = key.replace(`${i18nScope}.`, '');

              acc[key] = value;
            }
            return acc;
          },
          {}
        ) as I18nMessages;

        resolve(res);
      });
    } catch (err) {
      reject(err);
    }
  });
}
