import React from 'react';
import { Context } from '../context';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface TrackingManager {
  loaded(): void;
  impression(headlineInitialSlide: string, formatInitialSlide: string): void;
  navigateGallery(
    fromHeadline: string,
    targetAssetType: string,
    clickedElemLabel: string,
    clickId: string,
    fromSlideNo: number,
    targetSlideNo: number
  ): void;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface TrackingEvent {
  event: {
    eventInfo: {
      eventAction: string;
      eventName: string;
    };
    attributes: Record<string, unknown>;
  };
}

function createTrackingPayload(
  action: string,
  name: string,
  attributes: Record<string, unknown>
): TrackingEvent {
  const { host, pathname } = document.location;
  const defaultAttributes = {
    componentName: 'product-highlights-module',
    currentURL: `${host}${pathname}`,
  };

  return {
    event: {
      eventInfo: {
        eventAction: action,
        eventName: name,
      },
      attributes: {
        ...defaultAttributes,
        ...attributes,
      },
    },
  };
}

function getElementNameFromClickedElementLabel(clickedElemLabel: string): string {
  // check whether number of arrow was clicked
  // image | text link | arrow
  if (clickedElemLabel !== 'image') {
    const wasNumberClicked = !Number.isNaN(parseInt(clickedElemLabel, 10));
    return wasNumberClicked ? 'text link' : 'arrow';
  }
  return clickedElemLabel;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export function useTrackingManager(): TrackingManager {
  const { trackingService, logger } = React.useContext(Context);

  return React.useMemo((): TrackingManager => {
    function track(...args: Parameters<typeof createTrackingPayload>): void {
      if (trackingService) {
        const payload = createTrackingPayload(...args);
        logger?.debug('Sending tracking event: ', payload);
        trackingService.track(payload);
      }
    }

    return {
      loaded() {
        track('feature_app_ready', 'feature app ready', {
          componentName: 'Product Highlights',
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        });
      },

      impression(headlineInitialSlide: string, formatInitialSlide: string) {
        track('impression', 'Product Highlights - teaser impression', {
          componentName: 'Product Highlights',
          label: '',
          currentURL: window.location.href,
          targetURL: '',
          clickID: '',
          elementName: '',
          value: `${headlineInitialSlide}~${formatInitialSlide}`,
          pos: '1',
        });
      },

      navigateGallery(
        fromHeadline: string,
        targetAssetType: string,
        clickedElemLabel: string,
        clickId: string,
        fromSlideNo: number,
        targetSlideNo: number
      ) {
        track('navigation', 'product highlights - next teaser image', {
          componentName: 'Product Highlights',
          label: clickedElemLabel,
          currentURL: window.location.href,
          targetURL: '',
          clickID: clickId,
          elementName: getElementNameFromClickedElementLabel(clickedElemLabel),
          value: `${fromHeadline}~${targetAssetType}`,
          pos: `${fromSlideNo}~${targetSlideNo}`,
          actionType: clickedElemLabel === 'image' ? 'swipe' : 'click',
        });
      },
    };
  }, [trackingService]);
}
