import { Logger } from '@feature-hub/logger';
import {
  FootnoteReference,
  getConsumptionsAndEmissions,
} from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { VueFormatterServiceInterfaceV1 } from '@volkswagen-onehub/audi-vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';

import { createElement, ReactNodeArray } from 'react';
import { Content, InitialState, SlideContent } from '../FeatureAppTypes';
import { fetchI18nMessages } from '../i18n';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export async function createInitialState(
  content: Content,
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  logger: Logger | undefined
): Promise<InitialState> {
  const i18nMessages = await fetchI18nMessages(localeService, i18nService);

  const initialSlides = await Promise.allSettled(
    content.slides.map(async (slide): Promise<SlideContent> => {
      const consumptionAndEmissionData = slide?.wltp
        ? await getConsumptionsAndEmissions(slide.wltp, vueFormatterService, localeService, logger)
        : undefined;

      return {
        ...slide,
        wltpData: consumptionAndEmissionData,
      };
    })
  );

  const fulfilledSlides = initialSlides
    .filter(({ status }) => status === 'fulfilled')
    .map((fulfilled) => (fulfilled as PromiseFulfilledResult<SlideContent>).value);

  return { content, i18nMessages, slides: fulfilledSlides };
}

const deserializeReactNodeArrayInSlides = (slides: SlideContent[]) => {
  return slides.map((slide) => {
    if (!slide.wltpData) {
      return undefined;
    }
    const wltpData = slide.wltpData?.map((wltpItem) => {
      return {
        formattedConsumption: deserializeReactNodeArray(wltpItem.formattedConsumption),
        formattedEmission: deserializeReactNodeArray(wltpItem.formattedEmission),
      };
    });

    return {
      ...slide,
      wltpData,
    };
  });
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export function deserializeState(state: string): InitialState {
  const initialState = JSON.parse(state);
  return {
    ...initialState,
    slides: deserializeReactNodeArrayInSlides(initialState.slides),
  };
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const deserializeReactNodeArray = (
  deserializedProperty?: string | (string | Record<string, unknown>)[]
): undefined | string | ReactNodeArray => {
  if (!deserializedProperty || typeof deserializedProperty === 'string') {
    // if it's undefined or a string it doesn't contain any footnotes. Nothing to do here
    return deserializedProperty;
  }
  return deserializedProperty.map((serializedReactNode) => {
    if (typeof serializedReactNode === 'string') {
      return serializedReactNode;
    }
    // if it's not a string it has to be a <FootnoteReference /> react component
    return createElement(FootnoteReference, serializedReactNode?.props as undefined);
  });
};
