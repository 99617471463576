import { FeatureServices } from '@feature-hub/core';
import { Logger } from '@feature-hub/logger';
import { EnvConfigServiceV1 } from '@volkswagen-onehub/audi-env-config-service/dist/v1/EnvConfigServiceV1';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@volkswagen-onehub/audi-vue-formatter-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';

import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { ContentServiceV1 } from '@volkswagen-onehub/audi-content-service';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface Dependencies extends FeatureServices {
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'audi:envConfigService': EnvConfigServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
}
/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export type AsyncStateHolder = (() => Promise<InitialState>) | InitialState | undefined;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface AsyncFeatureAppProps {
  readonly asyncStateHolder: AsyncStateHolder;
}
/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface InitialState {
  content: Content;
  i18nMessages: I18nMessages;
  slides: SlideContent[];
}
/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export type I18nMessages = Record<I18nMessageKey, string>;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export type I18nMessageKey =
  | 'start'
  | 'pause'
  | 'previous'
  | 'next'
  | 'jump.to'
  | 'mute'
  | 'unmute';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export type Content = {
  themeGallery: ThemeGallery;
  slides: SlideContent[];
  wltp: string;
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export enum ThemeGallery {
  LIGHT = 'light',
  DARK = 'dark',
}
/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export enum ScreenOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface SsrWindow extends Partial<Window> {
  innerWidth: number;
  innerHeight: number;
}
/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export type UseWindow = Window | SsrWindow;

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface EditorSlideContent {
  headline: string;
  subline?: string;
  asset?: Asset;
  wltp?: string[];
  additionalText?: string;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface SlideContent {
  headline: string;
  subline?: string;
  asset?: Asset;
  wltp?: string[];
  additionalText?: string;
  wltpData?: ConsumptionsAndEmissions[] | undefined;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface SerializedSlideProps {
  formattedConsumption: string | (string | Record<string, unknown>)[] | undefined;
  formattedEmission: (string | Record<string, unknown> | undefined)[] | undefined;
}
/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface Asset {
  assetType: string;
  source?: {
    assetValue: string | any;
  };
  sourceMobile?: {
    assetValue: string | any;
  };
  alt?: string;
  svgUrl?: string;
  title?: string;
  url?: string;
  definition?: string;
  backgroundColor?: string;
  autoPlay?: boolean;
  loop?: boolean;
  hideMuteButton?: boolean;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface ImageProps {
  url: string;
  alt?: string;
  testId: string;
  loading?: string;
}
