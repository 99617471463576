import React from 'react';
import { I18nMessages } from '../FeatureAppTypes';

interface State {
  readonly i18nMessages: I18nMessages;
}

interface ContextValue {
  readonly children: JSX.Element;
  readonly i18nMessages: I18nMessages;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const I18nContext = React.createContext<State>({} as State);

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const I18nContextProvider = (props: ContextValue): JSX.Element => {
  const { children, i18nMessages } = props;

  const state: State = {
    i18nMessages,
  };

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
};
