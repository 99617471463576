import React, { useState } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import { Text } from '@audi/audi-ui-react';
import { useInViewEffect } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import sliderCss from 'react-responsive-carousel/lib/styles/carousel.css';
import { Icon, IconName } from './Icons';
import { SlideContent } from '../FeatureAppTypes';
import AssetSlide from './AssetSlide';
import { useTrackingManager } from '../utils/use-tracking-manager';
import { GalleryProps } from './ComponentTypes';
import TextSlide from './TextSlide';
import { matchI18nWithValues, useI18n } from '../i18n';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const SliderStyles = createGlobalStyle`

${sliderCss}
  // needed override to make flexbox children 100% height of their parent
  .carousel-root {
    height: 100%;
  }
  .carousel {
    height: 100%;
  }
  .slider-wrapper {
    height: 100%;
  }
  .slider {
    height: 100%;
    backface-visibility: hidden;
  }
  .carousel .slide {
    height: 100%;
    background: transparent;
    text-align: inherit;
  }
`;
const Background = styled.div`
  overflow: hidden;
  background-color: ${(props: { color: string }) => props.color};
`;

const Gallery = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.l}px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: 40% 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    grid-template-columns: 30% 70%;
  }
`;

const AssetsCarousel = styled.div`
  @media (orientation: landscape) {
    grid-column: 2;
    grid-row: 1;
  }
`;

const TextCarousel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0 var(${(props) => props.theme.responsive.spacing.xl});

  @media (orientation: landscape) {
    height: 100vh;
    justify-content: flex-start;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    justify-content: flex-end;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: end;
  user-select: none;
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0
    var(${(props) => props.theme.responsive.spacing.xl});
`;

const PageItem = styled.button`
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;

  & * {
    ${(props: { active: boolean; color: string }) =>
      props.active &&
      css`
        font-weight: bold;
        color: ${props.color};
        border-bottom: 2px solid ${props.color};
      `}
  }
`;

const PageArrow = styled.button`
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
`;

const CarouselSection = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.m}px) and (orientation: landscape) {
    overflow: auto;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 60%;
  }
`;

const getValidSlideNumber = (slideNo: number, numOfSlides: number): number => {
  if (slideNo >= numOfSlides) {
    // clicked next on last slide
    return slideNo % numOfSlides;
  }
  if (slideNo < 0) {
    // clicked left on first slide
    return numOfSlides + slideNo;
  }
  // value fits
  return slideNo;
};

const GallerySection: React.FC<GalleryProps> = ({ theme, slides }: GalleryProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [previousSlide, setPreviousSlide] = useState(0);
  const [swipeEvent, setSwipeEvent] = useState(false);

  if (!slides) return null;

  const featureAppId = __FEATURE_APP_NAME__;

  const trackingManager = useTrackingManager();

  // track navigation
  const trackNavigate = (
    fromSlideNo: number,
    targetSlideNo: number,
    clickedElemLabel: string,
    clickId: string
  ) => {
    const fromNo = getValidSlideNumber(fromSlideNo, slides.length);
    const targetNo = getValidSlideNumber(targetSlideNo, slides.length);
    trackingManager.navigateGallery(
      slides[targetNo]?.headline,
      slides[targetNo]?.asset?.assetType || 'image',
      clickedElemLabel,
      clickId,
      fromNo + 1,
      targetNo + 1
    );
  };

  React.useEffect(() => {
    trackingManager.loaded();
  }, []);

  useInViewEffect(() => {
    trackingManager.impression(slides[0]?.headline, slides[0]?.asset?.assetType || 'image');
  });

  React.useEffect(() => {
    if (swipeEvent) {
      trackNavigate(
        previousSlide,
        activeSlide,
        'image',
        `${featureAppId} Slide ${activeSlide + 1}`
      );
      setSwipeEvent(false);
    }
  }, [activeSlide]);

  return (
    <Background color={theme.colors.ui.primaryInverse} data-testid="gallery-section">
      <SliderStyles />
      <Gallery>
        <AssetsCarousel>
          <Carousel
            selectedItem={activeSlide}
            onChange={setActiveSlide}
            onSwipeEnd={() => {
              setPreviousSlide(activeSlide);
              setSwipeEvent(true);
            }}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            dynamicHeight={false}
            infiniteLoop
            autoPlay
            interval={3600000}
            swipeScrollTolerance={80}
            preventMovementUntilSwipeScrollTolerance
            useKeyboardArrows
          >
            {slides.map((slide: SlideContent, idx: number) => (
              <AssetSlide
                key={`assets_${slide.headline}`}
                content={slide}
                backgroundColor={slide.asset?.backgroundColor}
                active={idx === activeSlide}
                trackId={`${featureAppId} Slide ${idx + 1}`}
              />
            ))}
          </Carousel>
        </AssetsCarousel>
        <TextCarousel>
          <Pagination>
            <PageArrow
              aria-label={useI18n('previous')}
              id={`${featureAppId} ArrowLeft`}
              onClick={() => {
                trackNavigate(activeSlide, activeSlide - 1, '<', `${featureAppId} ArrowLeft`);
                setActiveSlide(activeSlide - 1);
              }}
            >
              <Icon name={IconName.Back} color={theme.colors.ui.primary} />
            </PageArrow>
            {slides.map((_: any, index: number) => (
              <PageItem
                key={`page_${_.headline}`}
                active={activeSlide === index}
                color={theme.colors.text.primary}
                aria-label={matchI18nWithValues('jump.to', { slideNo: (index + 1).toString() })}
                id={`${featureAppId} Pos ${index + 1}`}
                onClick={() => {
                  trackNavigate(
                    activeSlide,
                    index,
                    `'' ${index + 1}`,
                    `${featureAppId} Pos ${index + 1}`
                  );
                  setActiveSlide(index);
                }}
              >
                <Text
                  as="span"
                  data-testid={`page:button-${index}`}
                  spaceInlineEnd="m"
                  spaceInlineStart="m"
                >
                  {index + 1}
                </Text>
              </PageItem>
            ))}
            <PageArrow
              aria-label={useI18n('next')}
              id={`${featureAppId} ArrowRight`}
              onClick={() => {
                trackNavigate(activeSlide, activeSlide + 1, '>', `${featureAppId} ArrowRight`);
                setActiveSlide(activeSlide + 1);
              }}
            >
              <Icon name={IconName.Forward} color={theme.colors.ui.primary} />
            </PageArrow>
          </Pagination>
          <CarouselSection>
            <Carousel
              selectedItem={activeSlide}
              onChange={setActiveSlide}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop
              autoPlay
              interval={3600000}
              swipeScrollTolerance={80}
              preventMovementUntilSwipeScrollTolerance
              useKeyboardArrows
            >
              {slides.map((slide: SlideContent, index: Number) => (
                <TextSlide
                  key={`text_${slide.headline}`}
                  content={slide}
                  testId={`textslide-${index}`}
                />
              ))}
            </Carousel>
          </CarouselSection>
        </TextCarousel>
      </Gallery>
    </Background>
  );
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export default GallerySection;
