import {
  cleanupEmissionConsumptionMarkup,
  renderTextWithFootnotesReferences,
} from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';
import { ConsumptionsAndEmissions } from '@volkswagen-onehub/audi-vue-formatter-service';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const formatWltpData = (wltpData: ConsumptionsAndEmissions[] | undefined) => {
  if (wltpData) {
    return wltpData.map((wltpItem) => {
      return {
        formattedConsumption:
          wltpItem?.formattedConsumption &&
          renderTextWithFootnotesReferences(
            cleanupEmissionConsumptionMarkup(wltpItem?.formattedConsumption)
          ),

        formattedEmission:
          wltpItem?.formattedEmission &&
          renderTextWithFootnotesReferences(
            cleanupEmissionConsumptionMarkup(wltpItem?.formattedEmission)
          ),
      };
    });
  }
  return undefined;
};
