import React, { useContext } from 'react';
import styled from 'styled-components';

import { AssetHelper } from '../helper/assetHelper';

import { SvgImage, Image } from './Image';
import Video from './Video';
import { Context } from '../context';
import { AssetSlideProps } from './ComponentTypes';

const StyledSlide = styled.div<{ active: boolean }>`
  ${({ active }) => active && `z-index: 1;`}
  position: relative;
  height: 48vh;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) and (orientation: portrait) {
    height: 65vh;
  }

  @media (orientation: landscape) {
    height: 100vh;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) and (orientation: landscape) {
    height: 100vh;
  }
`;

const AssetSlide: React.FC<AssetSlideProps> = ({
  active,
  content: { asset },
  backgroundColor,
  trackId,
}) => {
  const additionalStyles = {
    ...(backgroundColor ? { backgroundColor } : null),
  };
  const { logger } = useContext(Context);

  return (
    <StyledSlide style={additionalStyles} active={active} id={trackId}>
      {asset && asset.assetType && asset.assetType === 'svg' && (
        <SvgImage src={asset.svgUrl || ''} alt={asset.alt} testId="image" loading="lazy" />
      )}
      {asset && asset.assetType && asset.assetType === 'image' && (
        <Image
          url={AssetHelper.getImageUrl(asset.source?.assetValue, logger)}
          alt={asset.alt}
          testId="image"
        />
      )}
      {asset && asset.assetType && asset.assetType === 'video' && (
        <Video video={asset} active={active} />
      )}
    </StyledSlide>
  );
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export default AssetSlide;
