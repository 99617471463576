import React, { useRef } from 'react';
import { ContextValue, State } from './ContextTypes';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const Context = React.createContext<State>({} as State);

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const ContextProvider = ({ featureServices, children }: ContextValue): JSX.Element => {
  const {
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'gfa:locale-service': localeService,
    'dbad:audi-i18n-service': i18nService,
    's2:logger': logger,
    'audi-tracking-service': trackingService,
    's2:async-ssr-manager': asyncSsrManager,
    's2:serialized-state-manager': serializedStateManager,
    'audi:envConfigService': envConfigService,
  } = featureServices;
  const inViewRef = useRef<HTMLDivElement>(null);

  const state: State = {
    logger,
    trackingService,
    inViewRef,
    asyncSsrManager,
    serializedStateManager,
    vueFormatterService,
    localeService,
    i18nService,
    envConfigService,
  };

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};
