import React from 'react';
import styled from 'styled-components';
import { LayoutItem, Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferences } from '@volkswagen-onehub/audi-etron-gt-utils-feature-app';

import { TextSlideProps } from './ComponentTypes';

const StyledSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
`;

const StyledCopy = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-right: var(${(props) => props.theme.responsive.spacing.xl});
  }
`;

const StyledFooter = styled(LayoutItem)`
  padding: var(${(props) => props.theme.responsive.spacing.l}) 0
    var(${(props) => props.theme.responsive.spacing.l}) 0;
`;

const TextSlide: React.FC<TextSlideProps> = ({ content, testId }) => {
  const { wltpData } = content;

  // eslint-disable-next-line react/no-unused-prop-types
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const WltpText: React.FC<{ testId: string }> = ({ children, testId }) => (
    <Text data-testid={testId} as="p" variant="copy2">
      {children}
    </Text>
  );

  const wltpItems = wltpData
    ? wltpData?.map((wltpItem, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <WltpText key={`con-${i}`} testId={`wltp-con-${testId}`}>
          {wltpItem.formattedConsumption} <br /> {wltpItem.formattedEmission}
        </WltpText>
      ))
    : [];

  content.additionalText &&
    wltpItems.push(
      <WltpText key="alt-phm" testId={`wltp-alt-${testId}`}>
        {renderTextWithFootnotesReferences(content.additionalText)}
      </WltpText>
    );

  return (
    <StyledSlide data-testid={testId}>
      <StyledCopy>
        <Text as="h3" variant="order3" data-testid={`headline-${testId}`} spaceStackEnd="s">
          {renderTextWithFootnotesReferences(content.headline)}
        </Text>
        {content.subline && (
          <Text as="p" variant="copy1" data-testid={`subline-${testId}`} spaceStackEnd="m">
            {renderTextWithFootnotesReferences(content.subline)}
          </Text>
        )}
      </StyledCopy>
      <StyledFooter>{wltpItems}</StyledFooter>
    </StyledSlide>
  );
};

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export default TextSlide;
