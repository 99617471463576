import { ScreenOrientation } from '../FeatureAppTypes';
import { getOrientation } from './get-orientation';

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export interface Orientation {
  orientation: ScreenOrientation;
}

/**
 * @deprecated This Feature App moved! New versions will be published as [@oneaudi/fa-app-product-highlights](https://github.com/oneaudi/fa-app-product-highlights). Update all of your dependencies now!
 */
export const useOrientation = (): Orientation => {
  const orientation = getOrientation();

  return {
    orientation,
  };
};
